import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, get, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
};

export const getAllContactData = createAsyncThunk(
  "vendor/getAllContactData",
  async (paramsContact) => {
    try {
      const response = await axiosInstance.get(
        `/inquiry${paramsContact}`,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllaccountsSlices = createAsyncThunk(
  "account/getAllaccountsSlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(`/post/video-request${params}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllUserList = createAsyncThunk(
  "setting/getAllUserList",
  async (params, thunkAPI) => {
    return thunkHandler(get(`/user${params}&role=user`), thunkAPI);
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/post/video-approved/${body?.id}?videoStatus=${body?.videoStatus}`,
        {},
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const accountsSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllaccountsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllaccountsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllaccountsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getAllUserList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllUserList.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllUserList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllContactData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllContactData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllContactData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { } = accountsSlice.actions;
export default accountsSlice.reducer;
