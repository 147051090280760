import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
};

export const getAllorderSlices = createAsyncThunk(
  "vendor/getAllorderSlices",
  async (paramsValue) => {
    try {
      const response = await axiosInstance.get(`/order${paramsValue}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getReports = createAsyncThunk(
  "vendor/getReports",
  async (paramsValue) => {
    try {
      const response = await axiosInstance.get(`/report${paramsValue}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getReportPost = createAsyncThunk(
  "vendor/getReportPost",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/post?_id=${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getAllorderByIdSlices = createAsyncThunk(
  "vendor/getAllorderByIdSlices",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/order?_id=${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (obj) => {
    try {
      const response = await axiosInstance.put(`/order/update-status/${obj?.id}`, obj?.body, {
        headers: authHeader(),
      });

      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    // selectCategoriesDataReducer: (state, action) => {
    //   state.selectCategoriesData = action?.payload;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllorderSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllorderSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllorderSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getAllorderByIdSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllorderByIdSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllorderByIdSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getReports.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getReports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getReportPost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportPost.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getReportPost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });;
  },
});
export const { selectCategoriesDataReducer } = orderSlice.actions;
export default orderSlice.reducer;
