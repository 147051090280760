import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, get, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  status: "idle",
};

export const getAllServiceSlices = createAsyncThunk(
  "vendor/getAllServiceSlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/service?${params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addServiceSlices = createAsyncThunk(
  "/auth/addServiceSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/service`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateServicesSlices = createAsyncThunk(
  "service/updateServicesSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/service/${body?.id}`,
        body?.servieData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteServicesSlices = createAsyncThunk(
  "category/deleteServicesSlices",
  async (body) => {
    try {
      const response = await axiosInstance.patch(
        `/service/${body?.id}`,
        body?.serviceData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const serviceSlice = createSlice({
  name: "servicesSlice",
  initialState: initialState,
  reducers: {
    selectCategoriesDataReducer: (state, action) => {
      state.selectCategoriesData = action?.payload;
    },
    selectSubCategoriesDataReducer: (state, action) => {
      state.selectSubCategoriesData = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllServiceSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllServiceSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllServiceSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      .addCase(addServiceSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addServiceSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addServiceSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateServicesSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateServicesSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateServicesSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteServicesSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteServicesSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteServicesSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      ;
  },
});
export const { selectCategoriesDataReducer, selectSubCategoriesDataReducer } =
  serviceSlice.actions;
export default serviceSlice.reducer;
