import React from "react";
import Logo from "../assets/img/logo/logo-blue.svg";

const vendorSubmitMessage = () => {
  return (
    <>
      <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center">
        <div className="max-w-md bg-white shadow-lg rounded-lg p-8 mx-4">
          <div className="text-center">
            <img loading="lazy"src={Logo} alt="Company Logo" className="mx-auto w-48 mb-4" />
            <h1 className="text-2xl font-bold mb-1">
              Vendor Account Request Submitted
            </h1>
            <p className="text-gray-600 mb-4">
              Your vendor application is still under review. You will receive
              details about our decision via email very soon!
            </p>
            <p className="text-gray-600 mb-4">
              Thank you for choosing our services. We look forward to serving
              you soon.
            </p>
          </div>
        </div>
        <footer className="mt-8 text-center text-gray-600">
          &copy; 2023 Your Company. All rights reserved.
        </footer>
      </div>
    </>
  );
};

export default vendorSubmitMessage;
