import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, get, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
  status: "idle",

};

export const getAllCategorySlices = createAsyncThunk(
  "vendor/getAllCategorySlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/category?${params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllAttributes = createAsyncThunk(
  "vendor/getAllAttributes",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/attributes/values?vendor_id=${params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllAttributeValues = createAsyncThunk(
  "vendor/getAllAttributeValues",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/attributes?vendor_id=${params.vendor}&type=${params.type}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addAttributesSlices = createAsyncThunk(
  "/vendor/addAttributesSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/attributes/bulk`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addCategorysSlices = createAsyncThunk(
  "/category/addCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/category`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteCategorys = createAsyncThunk(
  "vendor/deleteCategorys",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`category/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateCategorysSlices = createAsyncThunk(
  "category/updateCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/category/${body?.id}`,
        body?.categoryData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteCategorysSlices = createAsyncThunk(
  "category/deleteCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.patch(
        `/category/${body?.id}`,
        body?.categoryData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const getAllSubCategory = createAsyncThunk(
  "setting/getAllSubCategory",
  async (body, thunkAPI) => {
    return thunkHandler(
      get(`subCategory?category_id=${body?.id}${body?.params}`),
      thunkAPI
    );
  }
);

export const addSubCategorysSlices = createAsyncThunk(
  "/auth/addSubCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/subCategory`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteAttributeValue = createAsyncThunk(
  "vendor/deleteAttributeValue",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/attributes/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const deletePost = createAsyncThunk(
  "vendor/deletePost",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/post/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const deleteReport = createAsyncThunk(
  "vendor/deleteReport",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/report/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const deleteSubCategorys = createAsyncThunk(
  "vendor/deleteSubCategorys",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`subCategory/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateSubCategorysSlices = createAsyncThunk(
  "vendor/updateSubCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/subCategory/${body?.id}`,
        body?.categoryData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllChildCategory = createAsyncThunk(
  "vendor/getAllChildCategory",
  async (body) => {
    try {
      const response = await axiosInstance.get(
        `/childSubCategory?sub_category_id=${body?.id}${body?.params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addChildCategorysSlices = createAsyncThunk(
  "/auth/addChildCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/childSubCategory`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteChildCategorys = createAsyncThunk(
  "vendor/deleteChildCategorys",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`childSubCategory/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateChildCategorysSlices = createAsyncThunk(
  "vendor/updateChildCategorysSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/childSubCategory/${body?.id}`,
        body?.categoryData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    selectCategoriesDataReducer: (state, action) => {
      state.selectCategoriesData = action?.payload;
    },
    selectSubCategoriesDataReducer: (state, action) => {
      state.selectSubCategoriesData = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCategorySlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCategorySlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllCategorySlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCategorys.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategorys.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteCategorys.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getAllSubCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllSubCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllSubCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteSubCategorys.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSubCategorys.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteSubCategorys.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addSubCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSubCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addSubCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateSubCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateSubCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getAllChildCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllChildCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllChildCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addChildCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addChildCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addChildCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateChildCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateChildCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateChildCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteChildCategorys.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteChildCategorys.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteChildCategorys.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteCategorysSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategorysSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteCategorysSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      ;
  },
});
export const { selectCategoriesDataReducer, selectSubCategoriesDataReducer } =
  categorySlice.actions;
export default categorySlice.reducer;
