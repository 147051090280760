import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  axiosInstance,
  del,
  get,
  post,
  put,
  thunkHandler,
} from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
  selectItem: "true",
  selectedAttaribute: [],
};

export const getMainAttributes = createAsyncThunk(
  "product/getMainAttributes",
  async (id, thunkAPI) => {
    return thunkHandler(get(`/attributes/main`), thunkAPI);
  }
);

export const getSubAttributes = createAsyncThunk(
  "product/getSubAttributes",
  async (type, thunkAPI) => {
    return thunkHandler(get(`/attributes?type=${type}`), thunkAPI);
  }
);

export const createVeriants = createAsyncThunk(
  "product/createVeriants",
  async (body, thunkAPI) => {
    return thunkHandler(post(`/variants`, body), thunkAPI);
  }
);

export const getVeriant = createAsyncThunk(
  "product/getVeriant",
  async (id, thunkAPI) => {
    return thunkHandler(get(`/variants?product_id=${id}`), thunkAPI);
  }
);

export const updateVeriants = createAsyncThunk(
  "product/updateVeriants",
  async (body, thunkAPI) => {
    return thunkHandler(put(`/variants/${body?.id}`, body?.body), thunkAPI);
  }
);

export const bulkUpdateVeriants = createAsyncThunk(
  "product/bulkUpdateVeriants",
  async (body, thunkAPI) => {
    return thunkHandler(post(`variants/bulk-update`, body), thunkAPI);
  }
);

export const deleteVeriant = createAsyncThunk(
  "product/deleteVeriant",
  async (id, thunkAPI) => {
    return thunkHandler(del(`/variants/${id}`), thunkAPI);
  }
);

export const delteAllVeriants = createAsyncThunk(
  "product/delteAllVeriants",
  async (id, thunkAPI) => {
    return thunkHandler(del(`/variants/all/${id}`), thunkAPI);
  }
);

export const getAllproductSlices = createAsyncThunk(
  "product/getAllproductSlices",
  async (body) => {
    try {
      if (body?.loginId) {
        const response = await axiosInstance.get(
          `/product?vendor_id=${body?.loginId}${body?.selectItem}`,
          { headers: authHeader() }
        );
        return response.data;
      } else if (body?.productId) {
        const response = await axiosInstance.get(
          `/product?_id=${body?.productId}`,
          { headers: authHeader() }
        );
        return response.data;
      } else if (body?.params) {
        const response = await axiosInstance.get(`/product${body?.params}`, {
          headers: authHeader(),
        });
        return response.data;
      } else {
        const response = await axiosInstance.get(`/product`, {
          headers: authHeader(),
        });
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addProductsSlices = createAsyncThunk(
  "/product/addProductsSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/product`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/product/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/product/${body?.id}`,
        body?.productData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const uploadFile = createAsyncThunk(
  "/product/uploadFile",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/upload`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    updateSelectItem: (state) => {
      state.selectItem = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllproductSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllproductSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllproductSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addProductsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addProductsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(uploadFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getMainAttributes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMainAttributes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getMainAttributes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getSubAttributes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubAttributes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getSubAttributes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(createVeriants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createVeriants.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createVeriants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteVeriant.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVeriant.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteVeriant.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(delteAllVeriants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(delteAllVeriants.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(delteAllVeriants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateVeriants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateVeriants.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateVeriants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(bulkUpdateVeriants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkUpdateVeriants.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(bulkUpdateVeriants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { updateSelectItem } = productSlice.actions;
export default productSlice.reducer;
