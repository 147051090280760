import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, get, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
};

export const getAllPostalcodeSlices = createAsyncThunk(
  "vendor/getAllPostalcodeSlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/postal-code?${params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addPostcodeSlices = createAsyncThunk(
  "/auth/addPostcodeSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/postal-code`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updatePostcodeSlices = createAsyncThunk(
  "service/updatePostcodeSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/postal-code/${body?.id}`,
        body?.codeData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deletePostalcodeSlices = createAsyncThunk(
  "product/deletePostalcodeSlices",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/postal-code/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const postalcodeSlice = createSlice({
  name: "postalcode",
  initialState: initialState,
  reducers: {
    selectCategoriesDataReducer: (state, action) => {
      state.selectCategoriesData = action?.payload;
    },
    selectSubCategoriesDataReducer: (state, action) => {
      state.selectSubCategoriesData = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPostalcodeSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllPostalcodeSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllPostalcodeSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      .addCase(addPostcodeSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPostcodeSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addPostcodeSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updatePostcodeSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePostcodeSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updatePostcodeSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deletePostalcodeSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePostalcodeSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deletePostalcodeSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      ;
  },
});
export const { selectCategoriesDataReducer, selectSubCategoriesDataReducer } =
  postalcodeSlice.actions;
export default postalcodeSlice.reducer;
