import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
};

export const getAllVendors = createAsyncThunk(
  "/user?role=agent",
  async (paramsValue) => {
    try {
      const response = await axiosInstance.get(
        `/user${paramsValue}&role=agent`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getVendorId = createAsyncThunk("vendor/getVendorId", async () => {
  try {
    const response = await axiosInstance.get(`/role?name=vendor`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteVendors = createAsyncThunk(
  "vendor/deleteVendors",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`vendor/${id}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateVendors = createAsyncThunk(
  "vendor/updateVendors",
  async (body) => {
    try {
      const response = await axiosInstance.patch(
        `user/${body?.id}`,
        body?.vendorData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateAgents = createAsyncThunk(
  "agent/updateAgents",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/user/update-profile`,
        body?.vendorUpdateData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getVendorId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVendorId.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getVendorId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateVendors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateAgents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { } = vendorSlice.actions;
export default vendorSlice.reducer;
