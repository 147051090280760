import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import authSlice from "./ApiSlice/authSlice";
import vendorSlice from "./ApiSlice/vendorSlice";
import categorySlice from "./ApiSlice/categorySlice";
import productSlice from "./ApiSlice/productSlice";
import accountsSlice from "./ApiSlice/accountsSlice";
import controlSlice from "./ApiSlice/controlSlice";
import dashboardSlice from "./ApiSlice/dashboardSlice";
import orderSlice from "./ApiSlice/orderSlice";
import couponsSlice from "./ApiSlice/couponsSlice";
import serviceSlice from "./ApiSlice/serviceSlice";
import postalcodeSlice from "./ApiSlice/postalcodeSlice";
import addonsSlice from "./ApiSlice/addonsSlice";

const reducers = combineReducers({
  auth: authSlice,
  vendor: vendorSlice,
  category: categorySlice,
  product: productSlice,
  account: accountsSlice,
  control: controlSlice,
  dashboard: dashboardSlice,
  order: orderSlice,
  coupons: couponsSlice,
  service: serviceSlice,
  postalcode: postalcodeSlice,
  addons: addonsSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
  // devTools: false,
});

export const persistor = persistStore(store);
export default store;
