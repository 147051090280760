import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, del, patch, post, put, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
};

export const getAllCoupansSlices = createAsyncThunk(
  "coupons/getAllCoupansSlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(`/coupon${params}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const createCoupons = createAsyncThunk(
  "coupons/createCoupons",
  async (body, thunkAPI) => {
    return thunkHandler(post(`/coupon`, body), thunkAPI);
  }
);

export const updateCoupons = createAsyncThunk(
  "coupons/updateCoupons",
  async (body, thunkAPI) => {
    return thunkHandler(put(`/coupon/${body?.id}`, body?.payload), thunkAPI);
  }
);

// deleteCoupons

// export const deleteCoupons = createAsyncThunk(
//   "coupons/deleteCoupons",
//   async (id, thunkAPI) => {
//     return thunkHandler(del(`/coupen/${id}`), thunkAPI);
//   }
// );

export const deleteCoupons = createAsyncThunk(
  "coupons/deleteCoupons",
  async (body) => {
    try {
      const response = await axiosInstance.patch(`/coupon/${body.id}`,
        body.payload,
        {
          headers: authHeader(),
        });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const couponsSlice = createSlice({
  name: "coupons",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCoupansSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCoupansSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllCoupansSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(createCoupons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCoupons.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createCoupons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateCoupons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCoupons.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateCoupons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteCoupons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCoupons.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteCoupons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      ;
  },
});
export const { } = couponsSlice.actions;
export default couponsSlice.reducer;
