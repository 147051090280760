export const authHeader = () => {
  let sessionObj = getSession();
  if (sessionObj && sessionObj.access_token) {
    return {
      // Authorization: "Bearer " + sessionObj.access_token,
      "x-auth-token": sessionObj.access_token,
      "Content-Security-Policy": "default-src 'self',frame-src 'self'",
      "Access-Control-Allow-Origin": "*",
      "X-Content-Type-Options": "nosniff",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      // "Content-Type": "application/json",
      "X-Frame-Options": "SAMEORIGIN",
      "ngrok-skip-browser-warning": true,
      // "User-Agent": true,
    };
  } else {
    return {
      "Content-Security-Policy": "default-src 'self',frame-src 'self'",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      // "Content-Type": "application/json",
      "X-Frame-Options": "SAMEORIGIN",
      "X-Content-Type-Options": "nosniff",
      "ngrok-skip-browser-warning": true,
      // "User-Agent": true,
    };
  }
};

export const setSession = (sessionObj) => {
  localStorage.setItem("authUserDmorent", JSON.stringify(sessionObj));
};
export const getSession = () => {
  return JSON.parse(localStorage.getItem("authUserDmorent"));
};
export const deleteSession = () => {
  localStorage.removeItem("authUserDmorent");
};
