import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, post, put, thunkHandler } from "../../api/base";
import { authHeader, setSession } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
};

// export const logIn = createAsyncThunk("/user/login", async (body) => {
export const logIn = createAsyncThunk("/user/signin", async (body) => {
  try {
    // const response = await axiosInstance.post(`/user/login`, body);
    const response = await axiosInstance.post(`/user/signin`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const vendorLogIn = createAsyncThunk(
  "/auth/agentLogIn",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/user/signin`, body);
      if (response?.data?.payload?.role === "agent" || response?.data?.payload?.role === "admin") {
        return response.data;
      }
      else {
        alert("You are not authorized to login here")
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "/auth/verifyEmail",
  async (body) => {
    try {
      const response = await axiosInstance.post(
        `userService/user/send-email`,
        body
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

// export const verifyOtp = createAsyncThunk(
//   "auth/verifyOtp",
//   async (body, thunkAPI) => {
//     return thunkHandler(post(`/user/verify-otp`, body), thunkAPI);
//   }
// );
export const verifyOtp = createAsyncThunk("auth/verifyOtp", async (body, thunkAPI) => {
  const response = await thunkHandler(post(`/user/verify-otp`, body), thunkAPI);
  return { response, token: response?.payload?.token };
});

export const setNewPasswordWithToken = createAsyncThunk(
  "auth/setNewPasswordWithToken",
  async ({ token, newPasswordBody }) => {
    return thunkHandler(put(`/user/forgot-password`, newPasswordBody, { Authorization: `Bearer ${token}` }));
  }
);

// export const setNewPassword = createAsyncThunk(
//   "auth/setNewPassword",
//   async (body) => {
//     return thunkHandler(put(`/user/forgot-password`, body));
//   }
// );

// export const setNewPassword = createAsyncThunk(
//   "auth/setNewPassword",
//   async (body) => {
//     try {
//       const response = await axiosInstance.put(`/user/forgot-password`, body, {
//         headers: authHeader(),
//       });
//       return response.data;
//     }
//     catch (e) {
//       return e.response.data;
//     }
//   }
// );
//     return thunkHandler(put(`/user/forgot-password`, body, {
//       headers: authHeader(),
//     }));
//   }
// );

export const forgotPassword = createAsyncThunk(
  "/auth/forgotPassword",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/user/send-otp`, body);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const setSessionData = (token, userInfo) => {
  const sessionData = {
    access_token: token,
    userInfo: userInfo,
  };
  setSession(sessionData);
};

export const signUpVendor = createAsyncThunk(
  "/user/signupAgent",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/user/signup`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetAuth: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logIn.fulfilled, (state, action) => {
        const token = action?.payload?.payload?.token;
        let userInfo = action?.payload?.payload;
        if (token) {
          state.isLoggedIn = true;
          state.user = userInfo;
          state.status = "succeeded";
          setSessionData(token, userInfo);
        }
      })
      .addCase(logIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(vendorLogIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(vendorLogIn.fulfilled, (state, action) => {
        const token = action?.payload?.payload?.token;
        let userInfo = action?.payload?.payload;
        if (token) {
          state.isLoggedIn = true;
          state.user = userInfo;
          state.status = "succeeded";
          setSessionData(token, userInfo);
        }
      })
      .addCase(vendorLogIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(signUpVendor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signUpVendor.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(signUpVendor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetAuth, setUser } = authSlice.actions;
export default authSlice.reducer;
