import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  status: "idle",
  dashboardCount: {},
  dashboardSellCount: [],
  dashboardOrderCount: [],
  dashboardSlider: [],
  weeklyNewUser: [],
  searchChatUsersList: [],
  state: "idle",
};

export const getDashBoardSellCount = createAsyncThunk(
  "dashboard/getDashBoardSellCount",
  async (body) => {
    try {
      const response = await axiosInstance.get(
        `/user/dashboard/sells-chart?days=${body}`,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getDashBoardOrderCount = createAsyncThunk(
  "dashboard/getDashBoardOrderCount",
  async (body) => {
    try {
      const response = await axiosInstance.get(
        `/user/dashboard/orders-chart?days=${body}`,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getDashBoardCount = createAsyncThunk(
  "dashboard/getDashBoardCount",
  async (body) => {
    try {
      const response = await axiosInstance.get(`/user/dashboard`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getDashBoardData = createAsyncThunk(
  "dashboard/getDashBoardData",
  async (body) => {
    try {
      const response = await axiosInstance.get(`/user/dashboard-data`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getDashBoardSlider = createAsyncThunk(
  "dashboard/getDashBoardSlider",
  async (body) => {
    try {
      const response = await axiosInstance.get(`/product/top-products`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getDashBoardWeekUser = createAsyncThunk(
  "dashboard/getDashBoardWeekUser",
  async (body) => {
    try {
      const response = await axiosInstance.get(
        `/user/dashboard/user-chart?days=${body}`,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getSearchChatUsers = createAsyncThunk(
  "dashboard/getSearchChatUsers",
  async (body) => {
    try {
      const response = await axiosInstance.get(
        `user/getSearchChatUsers?limit=10&search=${body?.search}`,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    flushValues(state, action) {
      return {
        ...state,
        searchChatUsersList: []
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDashBoardCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboardCount = action?.payload?.payload;
      })
      .addCase(getDashBoardCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getDashBoardSellCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardSellCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboardSellCount = action?.payload?.payload;
      })
      .addCase(getDashBoardSellCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getDashBoardOrderCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardOrderCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboardOrderCount = action?.payload?.payload;
      })
      .addCase(getDashBoardOrderCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getDashBoardSlider.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardSlider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboardSlider = action?.payload?.payload;
      })
      .addCase(getDashBoardSlider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getDashBoardWeekUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardWeekUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.weeklyNewUser = action?.payload?.payload;
      })
      .addCase(getDashBoardWeekUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getSearchChatUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSearchChatUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.searchChatUsersList = action?.payload?.payload;
      })
      .addCase(getSearchChatUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getDashBoardData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashBoardData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.searchChatUsersList = action?.payload?.payload;
      })
      .addCase(getDashBoardData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      ;
  },
});
export const { flushValues } = dashboardSlice.actions;
export default dashboardSlice.reducer;
