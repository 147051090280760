import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, get, thunkHandler } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  user: {},
  isLoggedIn: false,
  selectCategoriesData: {},
  selectSubCategoriesData: {},
};

export const getAllAddonsSlices = createAsyncThunk(
  "vendor/getAllAddonsSlices",
  async (params) => {
    try {
      const response = await axiosInstance.get(
        `/addons?isAll=true&${params}`,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const addAddonsSlices = createAsyncThunk(
  "/auth/addAddonsSlices",
  async (body) => {
    try {
      const response = await axiosInstance.post(`/addons`, body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const updateAddonsSlices = createAsyncThunk(
  "service/updateAddonsSlices",
  async (body) => {
    try {
      const response = await axiosInstance.put(
        `/addons/${body?.id}`,
        body?.serviceData,
        { headers: authHeader() }
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteAddonsSlices = createAsyncThunk(
  "product/deleteAddonsSlices",
  async (body) => {
    try {
      const response = await axiosInstance.patch(`/addons/${body.id}`,
        body.serviceData,
        {
          headers: authHeader(),
        });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);


export const addonsSlice = createSlice({
  name: "addOns",
  initialState: initialState,
  reducers: {
    selectCategoriesDataReducer: (state, action) => {
      state.selectCategoriesData = action?.payload;
    },
    selectSubCategoriesDataReducer: (state, action) => {
      state.selectSubCategoriesData = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllAddonsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllAddonsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getAllAddonsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      .addCase(addAddonsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAddonsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addAddonsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateAddonsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAddonsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAddonsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteAddonsSlices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAddonsSlices.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteAddonsSlices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      ;
  },
});
export const { selectCategoriesDataReducer, selectSubCategoriesDataReducer } =
  addonsSlice.actions;
export default addonsSlice.reducer;
