import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/base";
import { authHeader } from "../../helpers/authHelper";

const initialState = {
  status: "idle",
  selectControlData: {},
};

export const getControlValue = createAsyncThunk(
  "product/getControlValue",
  async (body) => {
    try {
      const response = await axiosInstance.get(`${body}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const postControlData = createAsyncThunk(
  "product/postControlData",
  async (obj) => {
    try {
      const response = await axiosInstance.post(`${obj?.path}`, obj?.body, {
        headers: authHeader(),
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const controlSlice = createSlice({
  name: "control",
  initialState: initialState,
  reducers: {
    updateControlValue: (state, action) => {
      state.selectControlData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getControlValue.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getControlValue.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectControlData = action?.payload?.payload;
      })
      .addCase(getControlValue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(postControlData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postControlData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(postControlData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { updateControlValue } = controlSlice.actions;
export default controlSlice.reducer;
